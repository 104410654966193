type StockItem = {
  price: string;
  options: Record<string, string[]>;
}

export function formToObject(form: HTMLFormElement, ignoreAttr: string[] = []) {
  const fd = new FormData(form);
  const data = {};
  for (const [key, value] of fd.entries()) {
    if (!ignoreAttr.includes(key)) {
      data[key] = value;
    }
  }
  return data;
}

export function getStock(stock: StockItem[], form: Record<string, string>) {
  if (Object.keys(form).length === 0) { return null; }

  return stock.find(item =>
    Object.keys(form).every(option => 
      item.options[option]?.includes(form[option])
    )
  ) || null;
}

export function debounce(func: Function, wait: number) {
  let timeout: number;
  return function(...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  }
}